<template lang="pug">
.image-cropper(ref="wrapper" :style="wrapperStyle" :class="classList")
  transition(name="fade")
    .image-cropper-controls(
      ref="cropperControls"
      @click.stop=""
      :class="{ show: controlsVisible }"
    )
      .d-flex.flex-row.justify-space-between
        .image-cropper-controls-group.col-auto.d-flex.left
          om-button(small iconOnly primary icon="search-minus" @click.stop="onZoomOut")
          //- .zoom-slider(:class="{ show: showZoomSlider }")
            input(
              type="range"
              :min="zoom.min"
              :value="zoom.current"
              :max="zoom.max"
              step="0.01"
              @input="onZoomSliderChange"
            )
          om-button(small iconOnly primary icon="search-plus" @click.stop="onZoomIn")
        .image-cropper-controls-group.col-auto.d-flex.right
          om-button(
            small
            iconOnly
            primary
            :icon="visibleOverflow ? 'eye-slash' : 'eye'"
            @click.stop="onToggleOverflow"
          )
          om-button(small iconOnly primary icon="times" @click.stop="cancel")
          om-button(small iconOnly primary icon="check" @click.stop="save")
  vue-cropper(
    ref="cropper"
    drag-mode="move"
    :src="src"
    :responsive="true"
    :auto-crop-area="1"
    :guides="false"
    :center="false"
    :movable="true"
    :scalable="true"
    :crop-box-resizable="false"
    :crop-box-movable="false"
    :containerStyle="containerStyle"
    :toggleDragModeOnDblclick="false"
    :background="false"
    :highlight="false"
    :viewMode="0"
    :ready="onCropperReady"
    :restore="false"
    @zoom="onZoom"
  )
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
  import itemMixin from '@/editor/mixins/item';
  import { imagePositionTracker } from '@/services/userInteractionTracker/tracker';

  export default {
    components: { VueCropper },
    mixins: [itemMixin],
    data: () => {
      const workspaceRect = document
        .querySelector('.om-workspace-container')
        .getBoundingClientRect();

      return {
        containerStyle: {
          width: `${workspaceRect.width}px`,
          height: `${workspaceRect.height}px`,
        },
        wrapperStyle: {
          top: `${workspaceRect.top}px`,
          left: `${workspaceRect.left}px`,
          zIndex: 11,
          visibility: 'hidden'
        },
        visibleOverflow: false,
        initialZoom: 0,
        controlsVisible: false,
        showZoomSlider: false,
        zoom: null,
      };
    },
    computed: {
      ...mapState(['imageCropper']),
      src() {
        return this.imageCropper.colorInstance.getImageSrc();
      },
      classList() {
        return { 'visible-overflow': this.visibleOverflow };
      },
    },
    mounted() {
      document
        .getElementById('workspaceFrame')
        .contentDocument.addEventListener('keydown', this.onKeydown);
      document.addEventListener('keydown', this.onKeydown);
    },
    beforeDestroy() {
      document
        .getElementById('workspaceFrame')
        .contentDocument.removeEventListener('keydown', this.onKeydown);
      document.removeEventListener('keydown', this.onKeydown);
    },
    methods: {
      ...mapMutations(['hideImageCropper']),
      getElementDimensions() {
        const { width, height, top, left } = document
          .querySelector('#workspaceFrame')
          .contentWindow.document.getElementById(this.imageCropper.colorInstance.element.uid)
          .getBoundingClientRect();

        return { width, height, top, left };
      },
      onCropperReady() {
        const data = {};
        const crop = this.imageCropper.colorInstance.getImageCrop();

        const { width, height, top, left } = this.getElementDimensions();
        data.x = crop?.cropperData?.x ?? left;
        data.y = crop?.cropperData?.y ?? top;

        const workspaceRect = document.querySelector('#workspaceFrame').getBoundingClientRect();

        const containerRect = document
          .querySelector('.om-workspace-container')
          .getBoundingClientRect();

        data.width = width;
        data.height = height;
        data.top = top + workspaceRect.top - containerRect.top;
        data.left = left + workspaceRect.left - containerRect.left;

        const imageData = this.$refs.cropper.getImageData();
        this.$refs.cropper
          .setCropBoxData(data)
          .setCanvasData({
            left: data.left - imageData.width / 2 + data.width / 2,
            top: data.top - imageData.height / 2 + data.height / 2,
          });

        this.setZoomData(crop);
        this.setControlWrapperPosition(data);
        this.wrapperStyle.visibility = 'visible';
      },
      setZoomData(crop) {
        const image = this.$refs.cropper.getImageData()
        const { width: _imgWidth, height: _imgHeight } = image
        const roundedImgWidth = Math.round(_imgWidth);
        const roundedImgHeight = Math.round(_imgHeight);
        const isResized = crop && (roundedImgWidth !== Math.round(crop?.canvasData?.width) || roundedImgHeight !== Math.round(crop?.canvasData?.height))

        console.log('crop2', roundedImgWidth, Math.round(crop?.canvasData?.width), roundedImgHeight, Math.round(crop?.canvasData?.height))
        if (crop && !isResized) {
          this.$refs.cropper.setData(crop.cropperData);
          return
        }
        
        const { height: elHeight, width: elWidth } = this.getElementDimensions()
        let zoomLevel = crop?.zoom
        if (!zoomLevel) {
          const { width, height } = this.$refs.cropper.getCropBoxData();
          const { naturalHeight, naturalWidth } = this.$refs.cropper.getCanvasData();
          zoomLevel = height > width ? elHeight / naturalHeight : elWidth / naturalWidth;
        }
        this.$refs.cropper.zoomTo(zoomLevel);

        const { width: imgWidth, height: imgHeight, left: imgLeft, top: imgTop } = this.$refs.cropper.getImageData()
        const { left: cropBoxLeft, top: cropBoxTop } = this.$refs.cropper.getCropBoxData()
        const left = imgLeft || cropBoxLeft;
        const top = imgTop || cropBoxTop;
        const calcLeft = !isResized && imgWidth > elWidth ? left - ((imgWidth - elWidth) / 2) : left
        const moveLeft = !isResized && crop?.cropperData?.y ? crop?.cropperData?.x : calcLeft;
        const calcTop = !isResized && imgHeight > elHeight ? top - ((imgHeight - elHeight) / 2) : top
        const moveTop = !isResized && crop?.cropperData?.y ? crop?.cropperData?.y : calcTop;

        this.$refs.cropper.moveTo(moveLeft, moveTop)
      },
      setControlWrapperPosition(data) {
        const { width, left, top, height } = data;
        const controlWrapper = this.$refs.cropperControls;

        if (controlWrapper) {
          this.setStyle(controlWrapper, 'width', width, 'px');
          this.setStyle(controlWrapper, 'top', top + height, 'px');
          this.setStyle(controlWrapper, 'left', left, 'px');

          this.controlsVisible = true;
        }
      },
      setStyle(element, property, value, unit = '') {
        element.style[property] = `${value}${unit}`;
      },
      onKeydown(e) {
        if (e.key === 'Enter') {
          this.save();
        } else if (e.key === 'Escape') {
          this.cancel();
        }
      },
      save() {
        const { width, height } = this.getElementDimensions();
        const currentCrop = this.imageCropper.colorInstance.getImageCrop();
        const cropperData = this.$refs.cropper.getData();
        const canvas = this.$refs.cropper.getCroppedCanvas({
          width: width * 2,
          height: height * 2,
          imageSmoothingQuality: 'high'
        });
        let blob;
        const extension = this.src.split('.').pop();
        if (['jpg', 'jpeg'].includes(extension)) {
          blob = canvas.toDataURL('image/jpeg', 0.85);
        } else {
          blob = canvas.toDataURL(); // keep original file format
        }
        const imageData = this.$refs.cropper.getImageData();
        const canvasData = this.$refs.cropper.getCanvasData();
        const cropBoxData = this.$refs.cropper.getCropBoxData();
        const hasTypeChange = this.imageCropper.colorInstance.getImagePosition() !== 'crop';
        this.imageCropper.colorInstance.setImagePosition('crop');
        this.imageCropper.colorInstance.setImageCrop({
          previousUrl: currentCrop?.url || currentCrop?.previousUrl,
          base64: blob,
          cropperData,
          canvasData,
          cropBoxData,
          imageData,
          zoom: this.zoom
        });

        if (hasTypeChange) {
          imagePositionTracker.trackPositionTypeChange(
            this.imageCropper.actionLocation === 'workspace' ? 'fromWorkspace' : 'fromSidebar',
            this.imageCropper.colorInstance.getElementType(),
            'crop',
          );
        }

        this.$store.commit('handleColorInstanceToStore', this.imageCropper.colorInstance);
        this.$bus.$emit('rebuild-color-instance');
        this.hideImageCropper();
      },
      cancel() {
        this.hideImageCropper();
      },
      onToggleOverflow() {
        this.visibleOverflow = !this.visibleOverflow;
      },
      onZoom(event) {
        console.log(event)
        this.zoom = event?.detail?.zoom ?? null
      },
      onZoomIn() {
        this.$refs.cropper.cropper.zoom(0.1);
      },
      onZoomOut() {
        this.$refs.cropper.cropper.zoom(-0.1);
      },
      onZoomSliderChange(event) {
        const value = event?.target?.valueAsNumber;
        if (value) this.$refs.cropper.cropper.scale(value);
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .image-cropper
    position: absolute
    &-controls
      display: none
      position: absolute
      bottom: 0
      z-index: 1
      transition: .3s ease-out
      &.show
        display: block
      .btn
        border-radius: 0
      &-group
        margin: 0
        padding: 0
        .btn:first-child
          border-bottom-left-radius: 4px
        .btn:last-child
          border-bottom-right-radius: 4px
      .zoom-slider
        background-color: var(--brand-primary-color)
        padding: 0 5px
        display: none
        align-items: center
        justify-content: center
        &.show
          display: flex

        input[type='range']
          -webkit-appearance: none
          background-color: white
          width: 100%
          border-radius: 3px

        input[type='range']::-webkit-slider-runnable-track
          height: 2px
          -webkit-appearance: none
          color: white

        input[type='range']::-webkit-slider-thumb
          width: 12px
          -webkit-appearance: none
          height: 12px
          background: white
          border-radius: 100%
          margin-top: -6px
          border: 1px solid var(--brand-primary-color)
          box-sizing: content-box


  .image-cropper
    ::v-deep .cropper-modal
      opacity: .35
    ::v-deep .cropper-hide
      border: 1px solid $om-orange-500
    ::v-deep .cropper-view-box
      background: rgba(0,0,0,0.5)
      outline: 2px solid $om-orange-500
      border: none
    ::v-deep .cropper-canvas img
      opacity: 0
    ::v-deep .ui-svg-inline
      width: 1rem !important
      height: 1rem !important
    &.visible-overflow
      ::v-deep .cropper-canvas img
        opacity: .5
        border: 0
</style>
